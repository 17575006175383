//Author by Saidbek

// import { inject, Injectable, signal } from "@angular/core";
// import { environment } from "../../../../environments/environment";
// import { ToastService } from "@core/services/toast.service";
// import { TranslateService } from "@ngx-translate/core";
// import { AuthService } from "@app/modules/pages/login/auth.service";
// import { CurrentCallDetailsService } from "./current-call-details.service";
// import { Subject } from "rxjs";

// @Injectable()
// export class WebsocketService {
//   public receivedMessage: Subject<any> = new Subject<any>();
//   private socket: WebSocket;
//   private toast = inject(ToastService);
//   private transloco = inject(TranslateService);
//   private currentCallDetailsService = inject(CurrentCallDetailsService);
//   private pingInterval: any;
//   private pongTimeout: any;
//   private reconnectAttempts = 0;
//   private maxReconnectAttempts = 2;
//   public isLostSignal = signal(false);
//   currentUser: any;

//   constructor(private authenticationService: AuthService) {
//     this.authenticationService.currentUser.subscribe((x) => {
//       this.currentUser = x;
//       this.initializeWebSocketConnection();
//     });
//   }

//   getUserId(): string {
//     return this.currentUser?.id;
//   }

//   initializeWebSocketConnection(): void {
//     this.socket = new WebSocket(
//       `${environment.wsUrl}?User-id=${this.getUserId()}`
//     );
//     this.connect();
//   }

//   connect(): void {
//     this.socket.onopen = (event) => {
//       console.log("WebSocket connection established");
//       this.reconnectAttempts = 0;
//       this.startPingPong();
//     };

//     this.socket.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       if (data === "pong") {
//         clearTimeout(this.pongTimeout);
//       } else {
//         this.receivedMessage.next(data);
//       }
//     };

//     this.socket.onerror = (error) => {
//       // this.toast.error(
//       //   this.transloco.instant("Components.CallCenter.SocketError")
//       // );
//       console.error("WebSocket error:", error);
//     };

//     this.socket.onclose = (event) => {
//       if(this.reconnectAttempts === 0) {
//         this.isLostSignal.set(true);
//         this.toast.warning(
//           this.transloco.instant("Components.CallCenter.SocketClosed") + " WebSocket"
//         );
//       }
//       console.log("WebSocket connection closed");
//       this.cleanupTimers();
//       this.attemptReconnect();
//     };
//   }

//   private startPingPong(): void {
//     this.cleanupTimers();
//     this.pingInterval = setInterval(() => {
//       this.socket.send("ping");
//       this.setPongTimeout();
//     }, 5000);
//   }

//   private setPongTimeout(): void {
//     clearTimeout(this.pongTimeout);
//     this.pongTimeout = setTimeout(() => {
//       console.log("Pong qabul qilinmadi, WebSocketni yopish");
//       this.socket.close();
//     }, 5000);
//   }

//   private cleanupTimers(): void {
//     clearInterval(this.pingInterval);
//     clearTimeout(this.pongTimeout);
//   }

//   private attemptReconnect(): void {
//     if (this.reconnectAttempts < this.maxReconnectAttempts) {
//       this.reconnectAttempts++;
//       console.log(
//         `WebSocket qayta ulanishga urinish (Urinish ${this.reconnectAttempts})...`
//       );

//       setTimeout(() => {
//         this.initializeWebSocketConnection();
//       }, 3000);

//       // this.toast.warning(
//       //   this.transloco.instant(
//       //     "Aloqa sifati yomonlashdi!"
//       //   )
//       // );
//     } else {
//       console.log(
//         "Ulanmadi. Iltimos keyinroq qayta urinib ko'ring."
//       );

//       this.toast.error(
//         this.transloco.instant(
//           "Components.CallCenter.SocketError"
//         )
//       );


// this.currentCallDetailsService.removeData();

//     }
//   }
// }


//Author by Umid

// import { inject, Injectable } from "@angular/core";
// import { Subject } from "rxjs";
// import { environment } from "../../../../environments/environment";
// import { ToastService } from "@core/services/toast.service";
// import { AuthService } from "@app/modules/pages/login/auth.service";
// import { TranslateService } from "@ngx-translate/core";
// import { CurrentCallDetailsService } from "./current-call-details.service";
// import Swal from "sweetalert2";
//
// @Injectable()
// export class WebsocketService {
//   public receivedMessage: Subject<any> = new Subject<any>();
//   private socket: WebSocket;
//   private pingInterval: any;
//   private timeout: any;
//   private toast = inject(ToastService);
//   private currentCallDetailsService = inject(CurrentCallDetailsService);
//   private reconnectAttempts = 0;
//   private maxReconnectAttempts = 2;
//   private pingIntervalTime = 5000;
//   private pingTimeoutTime = 3000;
//   private reconnectDelayTime = 3000;
//   currentUser: any;
//
//   constructor(private _authenticationService: AuthService) {
//     this._authenticationService.currentUser.subscribe((x) => {
//       this.currentUser = x;
//       this.initializeWebSocketConnection();
//     });
//   }
//
//   getUserId(): string {
//     return this.currentUser?.id;
//   }
//
//   initializeWebSocketConnection(): void {
//     if (this.socket) {
//       console.log("Closing existing socket before initializing a new one.");
//       this.socket.close();
//     }
//
//     this.socket = new WebSocket(`${environment.wsUrl}?User-id=${this.getUserId()}`);
//     this.connect();
//   }
//
//   connect(): void {
//     this.socket.onopen = (event) => {
//       console.log("WebSocket connection established");
//
//       clearInterval(this.pingInterval);
//       clearTimeout(this.timeout);
//       this.reconnectAttempts = 0;
//
//       this.pingInterval = setInterval(() => {
//         this.socket.send("ping");
//         console.log("ping");      
//         this.timeout = setTimeout(() => {
//           this.toast.warning("Aloqa sifati yomonlashdi!")


//            let pingValue = false;

//           if(pingValue) {
//             setTimeout(() => {
//               console.log("YOPILDI");
//               Swal.fire({
//                 icon: "error",
//                 title: "Oops...",
//                 text: "WebSocket bilan ulanish yo'qoldi, Sahifani yangilang!",
//               });
//               this.currentCallDetailsService.removeData();
//               clearInterval(this.pingInterval);
//               this.socket.close();  
//             }, 20000)
//           }

//           pingValue = true
//         }, this.pingTimeoutTime);
//       }, this.pingIntervalTime);
//     };

//     this.socket.onmessage = (event) => {
//       clearTimeout(this.timeout);
//       this.receivedMessage.next(JSON.parse(event.data));
//     };

//     this.socket.onerror = (error) => {
//       console.log("WebSocket error", error);
//     };

//     this.socket.onclose = (event) => {
//       console.log("WebSocket connection closed", event);

//       if (this.reconnectAttempts < this.maxReconnectAttempts) {
//         this.reconnectAttempts++;
//         setTimeout(() => {
//           this.reconnect();
//         }, this.reconnectDelayTime);
//       } else {
//         console.log("Max reconnect attempts reached");
//         this.currentCallDetailsService.removeData();
//       }
//     };
//   }

//   reconnect(): void {
//     console.log("Attempting to reconnect WebSocket...");
//     this.initializeWebSocketConnection();
//   }
// }


// Old Code

import {inject, Injectable, signal} from '@angular/core';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ToastService} from '@core/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@app/modules/pages/login/auth.service';

@Injectable()
export class WebsocketService {
  public receivedMessage: Subject<any> = new Subject<any>();
  currentUser: any;
  public isLostSignal = signal(false);
  private socket: WebSocket;
  private toast = inject(ToastService);
  private translateService = inject(TranslateService);
  private timeout: any;
  private alertCount: number = 0;

  constructor(private _authenticationService: AuthService) {
    this._authenticationService.currentUser.subscribe(
      (x) => {
        this.currentUser = x;

        this.socket = new WebSocket(`${environment.wsUrl}?User-id=${this.getUserId()}`);
      }
    );
  }

  getUserId(): string {
    return this.currentUser?.id;
  }

  connect(): void {
    this.socket.onopen = (event) => {
      // this.toast.info(this.transloco.instant('Components.CallCenter.SocketConnected'));

      console.log('WebSocket connection established');

      setInterval(() => {
        this.socket.send('ping');

        this.timeout = setTimeout(() => {
          if (this.alertCount === 0) {
            this.toast.warning(this.translateService.instant('CommunicationHasDeteriorated') + '\nWebSocket');
            this.isLostSignal.set(true);
            this.alertCount++;
          }
        }, 2000);
      }, 15000);
    };

    this.socket.onmessage = (event) => {
      clearTimeout(this.timeout);
      this.receivedMessage.next(JSON.parse(event.data));
    };

    this.socket.onerror = (error) => {
      this.toast.error('CallCenter Websocket ' + this.translateService.instant('Components.CallCenter.SocketError'));

      console.error('WebSocket error:', error);
    };

    this.socket.onclose = (event) => {
      // this.toast.warning(this.transloco.instant('Components.CallCenter.SocketClosed') + ' WebSocket',);
      this.isLostSignal.set(true);

      console.log('WebSocket connection closed');
    };
  }
}
